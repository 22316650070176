const util = {
  clone(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }
    const isArr = Array.isArray(obj)
    const o = isArr ? [] : Object.create(null)
    if (isArr) {
      for (let i = 0, len = obj.length; i < len; i++) {
        o.push(util.clone(obj[i]))
      }
    } else {
      for (const i in obj) {
        o[i] = util.clone(obj[i])
      }
    }
    return o
  },
  getStorage(type, key) {
    if (!['session', 'local'].includes(type)) return null
    let result = null
    if (type === 'session') {
      result = window.sessionStorage.getItem(key)
    } else {
      result = window.localStorage.getItem(key)
    }
    try {
      result = JSON.parse(result)
    } catch (error) { }
    return result
  },
  setStorage(type, key, value) {
    if (!['session', 'local'].includes(type)) return null

    const v = typeof value === 'string' ? value : JSON.stringify(util.clone(value))

    if (type === 'session') {
      window.sessionStorage.setItem(key, v)
    } else {
      window.localStorage.setItem(key, v)
    }
  },
  delStorage(type, key) {
    if (!['session', 'local'].includes(type)) return null
    if (type === 'session') {
      window.sessionStorage.removeItem(key)
    } else {
      window.localStorage.removeItem(key)
    }
  },
  dateFormat(timestamp, type, sr, todayOrYesterday) {
    try {
      if (typeof timestamp === 'undefined' || timestamp === null || timestamp === '') {
        return '--'
      }
      const date = new Date(timestamp)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()

      const td = new Date()
      const yd = new Date((new Date()).setDate(td.getDate() - 1))
      const today = [td.getFullYear(), td.getMonth() + 1, td.getDate()].map(this.zeroFill).join(sr || '-')
      const yesterday = [yd.getFullYear(), yd.getMonth() + 1, yd.getDate()].map(this.zeroFill).join(sr || '-')

      let myday = [year, month, day].map(this.zeroFill).join(sr || '-')

      if (typeof todayOrYesterday !== 'undefined') {
        if (typeof todayOrYesterday !== 'boolean') {
          throw new Error('dateFormat: {todayOrYesterday}必须为Boolean类型')
        }
        const trans = this.getTrans().common
        if (today === myday) {
          myday = trans.today
        }
        if (yesterday === myday) {
          myday = trans.tomorrow
        }
      }

      if (typeof type !== 'undefined') {
        if (type !== 'day' && type !== 'minute' && type !== 'second') {
          throw new Error('dateFormat: {type}必须为day、minute、second中的一个')
        }
      }

      if (type === 'day') {
        return myday
      } else if (type === 'minute') {
        return myday + ' ' + [hour, minute].map(this.zeroFill).join(':')
      } else if (type === 'second') {
        return myday + ' ' + [hour, minute, second].map(this.zeroFill).join(':')
      } else {
        return myday
      }
    } catch (error) {
      console.error(error)
    }
  },
  uuid() {
    const s = []
    const hexDigits = 'abcdefABCDEF1234567890'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = ''

    return s.join('')
  },
  zeroFill(num) {
    num = num.toString()
    return num[1] ? num : '0' + num
  }
}
export default util
