import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './components'
import utils from './utils'
import toast from './views/compoents'

Vue.config.productionTip = false

Vue.use(toast, vuetify)
Vue.use(utils)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
