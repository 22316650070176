import loading from './loading.vue'
import toast from './toast.vue'

function loadMount (Vue, vuetify) {
  let Vm
  function createVm () {
    const vnodes = new Vue({
      vuetify,
      render: h => h(loading)
    }).$mount()
    document.body.appendChild(vnodes.$el)
    return vnodes.$children[0]
  }
  function show () {
    if (!Vm) {
      Vm = createVm()
    }
    Vm.show()
  }
  function hide () {
    if (Vm) {
      Vm.hide()
    }
  }
  Vue.prototype.$loading = show
  Vue.prototype.$loadend = hide
}
function toastMount (Vue, vuetify) {
  let Vm
  function createVm () {
    const vnodes = new Vue({
      vuetify,
      render: h => h(toast)
    }).$mount()
    document.body.appendChild(vnodes.$el)
    return vnodes.$children[0]
  }
  function open (text) {
    if (!Vm) {
      Vm = createVm()
    }
    Vm.toast(text)
  }
  Vue.prototype.$toast = open
}
function install (Vue, vuetify) {
  loadMount(Vue, vuetify)
  toastMount(Vue, vuetify)
}
export default { install }
