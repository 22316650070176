<template>
  <v-snackbar v-model="snackbar" elevation="24">
    {{text}}
    <template #action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'toast',
  data () {
    return {
      snackbar: false,
      text: ''
    }
  },
  methods: {
    toast (text) {
      this.text = text
      this.snackbar = true
    }
  }
}
</script>
