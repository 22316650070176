import Vue from 'vue'
import ViewSkeleton from './ViewSkeleton'
import UxSearchBar from './UxSearchBar'
import UxDatePicker from './UxDatePicker'
import UxTimePicker from './UxTimePicker'
import UxDateRange from './UxDateRange'
import SideBarSet from './SideBarSet'
import Popup from './Popup'

Vue.component('ViewSkeleton', ViewSkeleton)
Vue.component('UxSearchBar', UxSearchBar)
Vue.component('UxDatePicker', UxDatePicker)
Vue.component('UxTimePicker', UxTimePicker)
Vue.component('UxDateRange', UxDateRange)
Vue.component('SideBarSet', SideBarSet)
Vue.component('Popup', Popup)
