import Vue from 'vue'
import VueRouter from 'vue-router'
import { cux } from '../utils/cux'
import util from '../utils/util'
Vue.use(VueRouter)

const layout = () => import('@/views/layout.vue')

const routes = [
  {
    path: '/',
    component: layout,
    redirect: 'login',
    children: [
      {
        path: '/notesList',
        name: 'notesList',
        meta: {
          title: '笔记列表',
          isCache: true,
          breadcrumbs: [{
            text: '笔记列表',
            disabled: false,
            href: '#/notesList'
          },
          {
            text: '列表',
            disabled: true
          }]
        },
        component: () => import('@/views/notesList/index.vue')
      },
      {
        path: '/userList',
        name: 'userList',
        meta: {
          title: '用户列表',
          isCache: true,
          breadcrumbs: [{
            text: '用户列表',
            disabled: false,
            href: '#/userList'
          },
          {
            text: '列表',
            disabled: true
          }]
        },
        component: () => import('@/views/userList/index.vue')
      },
      {
        path: '/urlList',
        name: 'urlList',
        meta: {
          title: '推荐网址列表',
          isCache: true,
          breadcrumbs: [{
            text: '推荐网址列表',
            disabled: false,
            href: '#/urlList'
          },
          {
            text: '列表',
            disabled: true
          }]
        },
        component: () => import('@/views/urlList/index.vue')
      },
      {
        path: '/createNews',
        name: 'createNews',
        meta: {
          title: '创建信息',
          isCache: false,
          breadcrumbs: [{
            text: '创建',
            disabled: true
          },
          {
            text: '创建消息',
            disabled: true
          }]
        },
        component: () => import('@/views/createNews/index.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition
    if (to.hash) return { selection: to.hash }
    return { x: 0, y: 0 }
  }
})

const every = routes.every(v => {
  let flag = true
  if (v.children) {
    flag = v.children.every(v2 => {
      if (v2.meta && v2.meta.isCache) {
        return !!v2.name && !!v2.meta.title
      }
      return true
    })
  }
  if (v.meta && v.meta.isCache) {
    return flag && !!v.name && !!v.meta.title
  }
  return flag
})

if (!every) {
  console.warn('缓存路由中必须填写 name title 字段')
}
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const token = util.getStorage('local', 'token')
  if (token) return next()

  cux.alert({
    text: '登录过期',
    icon: 'error',
    timer: 1000
  }).then(() => {
    router.push({ path: '/login' })
  })
})
const push = VueRouter.prototype.push
VueRouter.prototype.push = function (params) {
  Reflect.apply(push, this, [params]).catch(() => { })
}

export default router
