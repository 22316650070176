import axios from 'axios'
import util from '@/utils/util'
import { cux } from '@/utils/cux'
import router from '@/router/index'

const env = process.env.NODE_ENV
const baseURL = env === 'development' ? '/api' : 'https://deap.ashanxinxi.com/'
// const baseURL = env === 'development' ? '/api' : 'https://paas-app-collection-31077.tvtvc.com/'
const instance = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
})

function optimizeParams(params) {
  if (typeof params === 'object' && params !== null) {
    if (params instanceof Array) {
      params.forEach(ele => {
        if (typeof ele === 'object' && ele !== null) {
          optimizeParams(ele)
        }
      })
    } else {
      for (const key in params) {
        if (key.indexOf('__') >= 0) {
          delete params[key]
        } else {
          if (typeof params[key] === 'object' && params[key] !== null) {
            optimizeParams(params[key])
          }
        }
      }
    }
  }
  return params
}

function post(url, content, isLoad = true) {
  if (isLoad) {
    this.$loading()
  }
  const token = util.getStorage('local', 'token')
  const now = Date.now()
  const today = util.dateFormat(now, 'day').replace(/-/g, '')

  const data = {
    content: optimizeParams(content),
    action: 'getMemberCodeDetail',
    uuid: `${today}-${util.uuid()}`,
    appId: 'ebuy7676bc482dff',
    lang: 'zh_CN',
    timestamp: now
  }
  return new Promise(resolve => {
    instance({
      headers: { token },
      method: 'post',
      url,
      data
    }).then(res => {
      this.$loadend()
      resolve(res.data)
      if (res.data.code !== 200 && res.data.message) {
        this.$toast(res.data.message)
      }
    }).catch(reject => {
      this.$loadend()
      if (reject.response.data.message === '401') {
        return cux.alert({
          text: '登录过期',
          icon: 'error',
          timer: 1000
        }).then(() => {
          util.delStorage('local', 'token')
          router.push({ path: '/login' })
        })
      }
      this.$toast(reject)
      resolve(reject)
    })
  })
}
export { post }
