<template>
  <v-dialog v-model="display" :overlay-opacity="0" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Loading
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'loading',
  data () {
    return {
      display: false
    }
  },
  methods: {
    show () {
      this.display = true
    },
    hide () {
      this.display = false
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card__text {
  text-align: center;
  font-size: 0.675rem;
}
</style>
