<template>
  <!-- 弹窗 -->
  <v-dialog scrollable v-model="popupShow" :width="width" @click:outside="cancel">
    <v-card>
      <v-card-title class="d-flex justify-center pt-7 pb-4">{{title}}</v-card-title>
      <v-card-text class="py-2" :style="{height: height + 'px',overflow: 'auto'}">
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-center py-4">
        <v-btn v-if="cancelBtn" class="mr-3" @click="cancel">{{cancelText}}</v-btn>
        <v-btn v-if="sureBtn" class="primary" @click="sure">{{sureText}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'popup',
  model: {
    value: 'value',
    event: 'toggle'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '标题'
    },
    cancelBtn: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: '返回'
    },
    sureBtn: {
      type: Boolean,
      default: true
    },
    sureText: {
      type: String,
      default: '保存'
    },
    width: {
      type: String,
      default: '750'
    },
    height: {
      type: String,
      default: '400'
    }
  },
  data() {
    return {
      popupShow: this.value
    }
  },
  watch: {
    value(newValue) {
      this.popupShow = newValue
    },
    popupShow(newValue) {
      this.$emit('toggle', newValue)
    }
  },
  methods: {
    open() {
      this.popupShow = true
    },
    close() {
      this.popupShow = false
    },
    cancel() {
      this.popupShow = false
      this.$emit('cancel')
    },
    sure() {
      this.$emit('onSave')
    }
  }
}
</script>
